<template>
	<div class="home">
		<div class="left">
			<div class="tree" v-loading='loading1'>
				<el-tree :expand-on-click-node="false" :data="tree" class="filter-tree" :props="defaultProps" :default-expanded-keys="[1]" :default-checked-keys="[1]"
				 :highlight-current="true" @node-click="nodeClick" ref="tree">
					<span class="my-custom" slot-scope="{node,data}" style="width: 100%;height: 100%;">
						<!-- <img :src="data.path" style="margin-right: 10px;" /> -->

						<span style="width: 100%;height: 100%;line-height: 32px;">{{data.grid_name}}</span>
					</span>
				</el-tree>
			</div>
		</div>
		<div class="right">
			<div class="transformer">
				<div class="clearfix electric-select">
					<el-form :inline="true">
						<el-form-item label="网格员名：">
							<el-input class="shuru" v-model="searchName"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="chaxun()">
								<i class="el-icon-search"></i>
								查询
							</el-button>
						</el-form-item>
					</el-form>
					
				</div>
				<div v-loading="loading2">
					<el-table :data="tableData" stripe style="width: 100%;margin-top: 20px;">
						<el-table-column prop="grid_name" label="网格" height show-overflow-tooltip align="center">
						</el-table-column>
						<el-table-column prop="name" label="网格员" width="250" height show-overflow-tooltip align="center">
						</el-table-column>
						<el-table-column label="是否开通" width="200" height show-overflow-tooltip align="center">
							<template slot-scope="scope">
								<!-- <el-button @click="xiugai(scope.row.id)" type="primary">修改</el-button> -->
								<span v-if="scope.row.type == null">未开通</span>
								<span v-else>已开通</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="200" height show-overflow-tooltip align="center">
							<template slot-scope="scope">
								<el-button v-if="scope.row.type == null" @click="xinzeng(scope.row)" type="primary">开通</el-button>
								<el-button v-if="scope.row.type != null" @click="xiugai(scope.row)" type="primary">修改</el-button>
								<el-button v-if="scope.row.type != null" @click="shanchu(scope.row.gridmanid)" type="primary">撤销</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="block">
						<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
						 :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
						</el-pagination>
					</div>
				</div>
				<el-dialog title="开通账号" width='30%' :visible.sync="dialogTableVisible" :before-close="handleClose"
				 :close-on-click-modal="false">
					<el-form ref="form" :model="form" label-width="100px">
						<el-form-item label="登录账号">
							<el-input v-model="form.login" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="密码">
							<el-input v-model="form.password"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit">立即开通</el-button>
							<el-button @click="close">取消</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
				<el-dialog title="修改" width='30%' :visible.sync="xiugaiVisible" :before-close="handleClose2"
				 :close-on-click-modal="false">
					<el-form ref="form" :model="form2" label-width="100px">
						<el-form-item label="登录账号">
							<el-input v-model="form2.zhanghao" :disabled="true"></el-input>
						</el-form-item>
						<el-form-item label="密码">
							<el-input v-model="form2.mima"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="onSubmit2">修改</el-button>
							<el-button @click="close2">取消</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getwanggelist,
		gridInmanlist,
		addManInfo,
		updateManInfo,
		deleteManInfo
	} from '@/api/wangge'
	export default {
		data() {
			return {
				time: new Date(),
				treeId: "",
				searchName: '',
				search:'',
				dialogTableVisible: false,
				tableData: [],
				currentPage: 1,
				fenye: {
					pagesizes: [10,20,30,40],
					pagesize: 10,
					totalnum: 0
				},
				xiaoqu: '',
				options: [],
				xuanxiang:[],
				wgy: '',
				
				max: '',
				dan: '',
				defaultProps: {
					children: 'wanggelist',
					label: 'grid_name'
				},
				treeSelectId: "",
				tree: [],
				regioncode: '',
				levelcode: '',
				gridid:'',
				levelname:'',
				wanggename:'',
				form:{
					login:'',
					password:'000000',
					manid:''
				},
				form2:{
					zhanghao:'',
					mima:''
				},
				xiugaiVisible:false,
				loading1:true,
				loading2:true
			}
		},
		watch: {
			// duoxuan(val, oldVal) {
			// 	console.log(val)
			// }
		},
		mounted() {
			this.getList();
			this.getTree();
		},
		methods: {
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.fenye.pagesize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			getTree() {
				this.loading1 = true;
				getwanggelist({
					'x-access-token': this.GLOBAL.token(),
					userid: this.GLOBAL.adminId(),
				}).then((res) => {
					this.loading1 = false;
					if (res.result == '200') {
						this.tree = res.detail.levelCode;
						// console.log(this.tree);
						for (var a = 0; a < this.tree.length; a++) {
							this.tree[a].grid_name = this.tree[a].name;
							this.tree[a].ji = 1;
							this.tree[a].num = a;
							for (var i = 0; i < this.tree[a].wanggelist.length; i++) {
								this.tree[a].wanggelist[i].name = this.tree[a].name.split('(')[0];
								this.tree[a].wanggelist[i].ji = 2;
								this.tree[a].wanggelist[i].num = i;
								this.tree[a].wanggelist[i].prevnum = a;
							}
						}
					
						// this.tableData = this.tree[0].wanggelist;
						// this.$nextTick(() => {
						// 	this.$refs.tree.setCurrentKey(3);
						// })
					} else {
						this.$message.error(res.description);
					}
				})
			},
			handleClose: function(done) {
				this.form = {
					login:'',
					password:'000000',
					manid:''
				}
				done();
			},
			handleClose2: function(done) {
				this.form2 = {
					zhanghao:'',
					mima:''
				}
				done();
			},
			onSubmit() {
				var that = this;
				if(that.form.password == ''){
					that.$message.error('请输入密码');
					return;
				}
				addManInfo({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					manid: that.form.manid,
					name:that.form.name,
					login: that.form.login,
					password: that.form.password
				}).then((res) => {
					if (res.result == '200') {
						that.$message({
							message: '添加成功',
							type: 'success'
						});
						that.searchName = '';
						that.getList();
						that.dialogTableVisible = false;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			onSubmit2() {
				var that = this;
				if(that.form2.mima == ''){
					that.$message.error('请输入密码');
					return;
				}
				updateManInfo({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					manid: that.form2.manid,
					password: that.form2.mima
				}).then((res) => {
					if (res.result == '200') {
						that.$message({
							message: '修改成功',
							type: 'success'
						});
						that.searchName = '';
						that.getList();
						that.xiugaiVisible = false;
					} else {
						this.$message.error(res.description);
					}
				})
			},
			close() {
				var that = this;
				that.form = {
					login:'',
					password:'000000',
					manid:''
				}
				that.dialogTableVisible = false;
			},
			close2() {
				var that = this;
				that.form2 = {
					zhanghao:'',
					mima:''
				}
				that.xiugaiVisible = false;
			},
			shanchu(a) {
				var that = this;
				that.$confirm('此操作将删除网格员的账号, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					deleteManInfo({
						'x-access-token': that.GLOBAL.token(),
						userid: that.GLOBAL.adminId(),
						manid:a
					}).then((res) => {
						if (res.result == '200') {
							that.$message({
								type: 'success',
								message: '删除成功!'
							});
							that.searchName = '';
							that.search = '';
							that.currentPage = 1;
							that.getList();
						} else {
							that.$message.error(res.description);
						}
					})
					
				}).catch(() => {
				
				});
			},
			chaxun(){
				this.currentPage = 1;
				this.search = this.searchName;
				this.getList();
			},
			getList() {
				var that = this;
				that.loading2 = true;
				gridInmanlist({
					'x-access-token': that.GLOBAL.token(),
					userid: that.GLOBAL.adminId(),
					gridlevel:that.levelcode,
					gridid:that.gridid,
					gridmanid:'',
					page:that.currentPage,
					pagesize:that.fenye.pagesize,
					seachname: that.search
				}).then((res) => {
					that.loading2 = false;
					if (res.result == '200') {
						that.tableData = res.detail.list;
						that.fenye.totalnum = res.detail.totalRow;
					} else {
						that.$message.error(res.description);
					}
				})
			},
			nodeClick(data) {
				// console.log(data);
				var that = this;
				that.treeClick(data);

			},
			treeClick(a) {
				var that = this;
				that.searchName = '';
				that.search = '';
				that.levelcode = '';
				that.gridid = '';
				that.levelname = '';
				that.currentPage = 1;
				that.wanggename = '';
				if (a.ji == 2) {
					that.gridid = a.id;
					that.levelname = a.name;
					that.levelcode = a.grid_level;
					that.wanggename = a.grid_name;
					
				} else {
					that.levelcode = a.levelcode;
				}
				that.getList();
			},
			//开通
			xinzeng(a){
				var that = this;
				that.dialogTableVisible = true;
				that.wgy = '';
				that.form.name = a.name;
				that.form.login = a.phone;
				that.form.manid = a.gridmanid;
				// that.$axios({
				// 	method: "post",
				// 	url: "/wangge/getGridManlist",
				// 	params: {
				// 		'x-access-token': that.GLOBAL.token(),
				// 		userid: that.GLOBAL.adminId(),
				// 		levelcode: that.levelcode
				// 	}
				// }).then(res => {
				// 	//console.log(JSON.stringify(res))
				// 	if (res.result == '200') {
				// 		that.xuanxiang = res.detail;
				// 	} else {
				// 		that.$message.error(res.description);
				// 	}
				// })
			},
			//修改密码
			xiugai(a) {
				var that = this;
				that.form2 = {
					zhanghao : a.phone,
					mima : a.password,
					manid:a.gridmanid
				};
				that.xiugaiVisible = true;
			}
		},

	}
</script>

<style lang="scss" scoped="scoped">
	.home {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;
		background: #fff;
		border: solid 1px #d7dde4;
		box-shadow: 0 0 3px rgba($color: #d7dde4, $alpha: 0.3);

		.left {
			width: 320px;
			height: 100%;
			overflow: auto;
			box-sizing: border-box;
			border: solid 1px #d7dde4;
			float: left;

			.search {
				width: 100%;
				box-sizing: border-box;
				padding: 16px;
				border-bottom: solid 1px #d7dde4;
			}

			& /deep/ .el-tree {
				.el-tree-node__content {
					height: 32px;
				}

				.el-icon-caret-right {
					width: 14px;
					text-align: center;
					padding-left: 16px;
					padding-right: 8px;
				}

				.el-icon-caret-right:not(.expanded):before {
					content: "+";
					font-size: 18px;
					font-weight: bold;
					transform: none;
					color: #848694;
				}

				.el-tree-node__expand-icon.expanded {
					transform: rotate(0deg);
				}

				.el-tree-node__expand-icon.expanded:before {
					content: "-";
					font-size: 18px;
					font-weight: bold;
					color: #848694;
				}

				.is-leaf {
					opacity: 0;
				}

				.my-custom {
					font-size: 14px;

					& * {
						display: inline-block;
						vertical-align: middle;
					}
				}
			}
		}

		.right {
			width: calc(100% - 320px);
			height: 100%;
			box-sizing: border-box;
			padding: 0 16px;
			float: left;

		}
	}

	.right>div {
		box-sizing: border-box;
		height: calc(100% - 32px);
		overflow-y: auto;
	}

	.loudong>p,
	.loudong>div {
		padding: 10px 0;
	}
</style>
