import request from '@/utils/request'

export function getwanggelist(data) {
	return request({
		url: '/wangge/getwanggelist',
		method: 'get',
		params: data
	})
}
export function addwangege(data) {
	return request({
		url: '/wangge/addwangege',
		method: 'post',
		params: data
	})
}
export function updatewangege(data) {
	return request({
		url: '/wangge/updatewangege',
		method: 'post',
		params: data
	})
}
export function gridlist(data) {
	return request({
		url: '/wangge/gridlist',
		method: 'post',
		params: data
	})
}
export function deletewangege(data) {
	return request({
		url: '/wangge/deletewangege',
		method: 'post',
		params: data
	})
}
export function updateGridMapInfo(data) {
	return request({
		url: '/wangge/updateGridMapInfo',
		method: 'post',
		params: data
	})
}
export function getbuildlist(data) {
	return request({
		url: '/region/getbuildlist',
		method: 'post',
		params: data
	})
}
export function addGridBuild(data) {
	return request({
		url: '/wangge/addGridBuild',
		method: 'post',
		params: data
	})
}
export function updateGridBuild(data) {
	return request({
		url: '/wangge/updateGridBuild',
		method: 'post',
		params: data
	})
}
export function gridbuildlist(data) {
	return request({
		url: '/wangge/gridbuildlist',
		method: 'post',
		params: data
	})
}
export function xiaoqulist(data) {
	return request({
		url: '/region/xiaoqulist',
		method: 'post',
		params: data
	})
}
export function addGridMan(data) {
	return request({
		url: '/wangge/addGridMan',
		method: 'post',
		params: data
	})
}
export function updateGridMan(data) {
	return request({
		url: '/wangge/updateGridMan',
		method: 'post',
		params: data
	})
}
export function deleteGridMan(data) {
	return request({
		url: '/wangge/deleteGridMan',
		method: 'post',
		params: data
	})
}
export function getGridManlist(data) {
	return request({
		url: '/wangge/getGridManlist',
		method: 'post',
		params: data
	})
}
export function addgridInman(data) {
	return request({
		url: '/wangge/addgridInman',
		method: 'post',
		params: data
	})
}
export function deletegridInman(data) {
	return request({
		url: '/wangge/deletegridInman',
		method: 'post',
		params: data
	})
}
export function gridInmanlist(data) {
	return request({
		url: '/wangge/gridInmanlist',
		method: 'post',
		params: data
	})
}
export function addManInfo(data) {
	return request({
		url: '/wangge/addManInfo',
		method: 'post',
		params: data
	})
}
export function updateManInfo(data) {
	return request({
		url: '/wangge/updateManInfo',
		method: 'post',
		params: data
	})
}
export function deleteManInfo(data) {
	return request({
		url: '/wangge/deleteManInfo',
		method: 'post',
		params: data
	})
}